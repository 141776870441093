/* global $ */

class DownloadText {
    constructor(ClassName,AfterText) {
        if ('ontouchstart' in window && navigator.maxTouchPoints > 0) {
            if ('userAgentData' in window.navigator) {
                if (navigator.userAgentData.platform !== "Windows") {
                    $(ClassName).text(AfterText);
                }
            }
            else {
                $(ClassName).text(AfterText);
            }
        }
    }
}

export default DownloadText;