/* global $ */

class Active {
    constructor(ClassName) {
        const SelectorName = $(ClassName);
        SelectorName.click(function () {
          $(this).toggleClass('Is-active');
        });

    $(document).click(function (e) {
      if (
        !$(e.target).closest(ClassName).length &&
        SelectorName.hasClass('Is-active')
      ) {
        SelectorName.toggleClass('Is-active');
      }
    });
  }
}

export default Active;
