/* global $ */

class DownloadLink {
    constructor(ClassName) {
        const SelectorName = $(ClassName);
        this.$window = $(window);
        const ua = window.navigator.userAgent.toLowerCase();

        if (ua.indexOf('windows') !== -1) {
            // windowsの場合は何もしない
            // 単純に/downloadページへ遷移して欲しい
        } else {
            // windows意外の場合

            if (window.navigator.share) {
                // シェア機能が有効な端末の場合の処理
                // (それ意外の場合は何もしない=/downloadへ遷移)

                // 「PCへ送る」等に表示を切り替える処理？
                // 例として一番シンプルな「クラスを当てる」方法を取っているが、どんなやり方でも良い
                SelectorName.addClass('disabled');

                // ボタンをクリックされた場合には、画面遷移を無効にした上でシェアシートを立ち上げる
                SelectorName.on('click', async (e) => {
                    e.preventDefault();
                    try {
                        await window.navigator.share({
                            title: '',
                            text: '',
                            url: 'https://toruno.biz/',
                        });
                    } catch (e) {
                        console.log(e.message);
                    }
                });
            }
        }
    }
}

export default DownloadLink;