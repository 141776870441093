/* global $ */

class StickyImage {
  constructor() {
    const StickyImage = $('.js-StickyImage').find('.Image');

    const StickyImageTrigger = $('.js-StickyImageTrigger');

    $(window).on("load scroll", function(){
        StickyImageTrigger.each(function (index) {

            const TriggerPos = StickyImageTrigger.eq(index).offset().top;

            const scroll = $(window).scrollTop();


            if (scroll > TriggerPos - 80 ) {
                StickyImage.eq(index).addClass('is-show');
            }

            else {
                StickyImage.eq(index).removeClass('is-show');
            }
        });
    });
  }
}

export default StickyImage;
