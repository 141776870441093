/* global $ */

class Tab {
    constructor() {
        const TabButtonName = "TabButton";
        const TabButtonSelector = "." + TabButtonName;
        const TabButtonNameActive = TabButtonName + "--active";
        const TabButtonSelectorActive = "." + TabButtonNameActive;

        const TabContentName = "TabContent";
        const TabContentSelector = "." + TabContentName;
        const TabContentNameHidden = TabContentName + "--hidden";
        const TabContentNameShow = TabContentName + "--show";
        const TabContentSelectorShow = "." + TabContentNameShow;

        $(TabButtonSelector).click(function () {
            $(TabButtonSelectorActive).removeClass(TabButtonNameActive);
            $(this).addClass(TabButtonNameActive);
            $(TabContentSelector).removeClass(TabContentNameHidden);
            $(TabContentSelectorShow).removeClass(TabContentNameShow).addClass(TabContentNameHidden);
            const index = $(this).index();
            $(TabContentSelector).eq(index).addClass(TabContentNameShow);
        });
    }
}

export default Tab;