/* global $ */

class DownloadIcon {
    constructor(ClassName,BeforeIcon,AfterIcon) {

        if ('ontouchstart' in window && navigator.maxTouchPoints > 0) {
            if ('userAgentData' in window.navigator) {
                if (navigator.userAgentData.platform !== "Windows") {
                    $(ClassName).addClass(AfterIcon);
                    $(ClassName).removeClass(BeforeIcon);
                }
            }
            else {
                $(ClassName).addClass(AfterIcon);
                $(ClassName).removeClass(BeforeIcon);
            }
        }


    }
}

export default DownloadIcon;