/* global $ */

class StickyButtonShow {
    constructor() {
        const LpMv = $('.Section--lp-top');
        const LpMvBottom = LpMv.offset().top + LpMv.outerHeight();
        const StickyArea = $('.StickyArea');
        const Footer = $('.Footer');
        const FooterOffset = Footer.offset().top;

        $(window).on("load scroll", function () {
            if ($(window).scrollTop() >= LpMvBottom - 300 && $(window).scrollTop() + $(window).height() <= FooterOffset) {
                StickyArea.addClass('StickyArea--show');
            }
            else {
                StickyArea.removeClass('StickyArea--show');
            }
        });
    }
}

export default StickyButtonShow;