import DownloadLink from './DownloadLink';
import DownloadText from './DownloadText';
import DownloadIcon from './DownloadIcon';

class RichButtonSwitcher {
    constructor() {
        new DownloadText('.RichButton--personal .RichButtonMainText__caption .Text', 'Windows PCでダウンロード可能');
        new DownloadText('.RichButton--personal .RichButtonMainText__label .Text', 'PCにリンクを送る');
        new DownloadLink('.RichButton--personal', 'https://toruno.biz/');
        new DownloadIcon('.RichButton--personal .Icon--download','Icon--download', 'Icon--share');
    }
}

export default RichButtonSwitcher;