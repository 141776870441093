import jQuery from 'jquery';
import Swiper from 'swiper/bundle';
import 'jquery.easing';
import Accordion from './modules/Accordion';
import Tab from './modules/Tab';
import Header from './modules/Header';
import HamburgerMenu from './modules/HamburgerMenu';
import SmoothScroll from './modules/SmoothScroll';
import ScrollTrigger from './modules/ScrollTrigger';
import Loading from './modules/Loading';
import RichButtonSwitcher from './modules/RichButtonSwitcher';
import RectButtonSwitcher from './modules/RectButtonSwitcher';
import StickyButtonSwitcher from './modules/StickyButtonSwitcher';
import StickyImage from './modules/StickyImage';
import StickyButtonShow from './modules/StickyButtonShow';
import Active from './modules/Active';
import DownloadLink from './modules/DownloadLink';

const $ = jQuery;
window.jQuery = $;
window.$ = $;

console.log('RICOH toruno');

class MainApp {
  constructor() {
    this.$window = $(window);

    $(function () {
      new Header;

      new Accordion;

      new DownloadLink(".js-Download");
      new DownloadLink(".RichButton--personal");

      new Tab;

      new Active(".HeaderNavigationList__item--has-child");

      new HamburgerMenu;

      new SmoothScroll(-90, 400);

      new RichButtonSwitcher;
      new RectButtonSwitcher;

      new Swiper('.js-ImgSwiper', {
        loop: true,
        speed: 500,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: true,
          disableOnInteraction: false,
        },
        spaceBetween: 24
      });

      $(window).on("load scroll", function(){
        new ScrollTrigger('.js-fadeIn');
        new ScrollTrigger('.js-fadeUp');
        new ScrollTrigger('.js-RoundBlockShow');
        new ScrollTrigger('.js-CardShow');
        new ScrollTrigger('.js-FooterShow');
        new ScrollTrigger('.js-ColorBgShow');
        new ScrollTrigger('.js-StepShow');
      });


    });
  }

  scrollPage(val, isCancelTransition = false) {
    if (isCancelTransition) {
      $(this.scrollableElement).stop(false).scrollTop(val);
    } else {
      $(this.scrollableElement)
        .stop(false)
        .animate({ scrollTop: val }, 1000, 'easeInOutExpo');
    }
  }

  isTouchDevice() {
    return 'ontouchstart' in window || navigator.msPointerEnabled
      ? true
      : false;
  }

  onWindowResize() {
    this.onWindowScroll();
  }

  onWindowScroll() {
    this.scrollTop = this.$window.scrollTop();
  }
}

$('document').ready(() => {
  new MainApp();
});

$(window).on('load', () => {

  new Loading;

  if (document.URL.match("/product/")) {
    new StickyImage;
    // new StickyContentShow;
  }

  if (document.URL.match("/lp/")) {
    new StickyButtonShow
    new StickyButtonSwitcher;
  }

});
